import get from 'lodash/get'
import pick from 'lodash/pick'
import forEach from 'lodash/forEach'
import videojs from 'video.js'
import 'videojs-contrib-quality-levels'
import '@less/index'
import '@plugins/NFBTheme'

const defaultOptions = {
  source: null, // required
  dvSource: null, // optional

  // inherit from videoJS
  poster: false,
  controls: true,
  autoplay: false,
  loop: false,
  muted: false,
  preload: 'metadata',
  language: 'en',
  inactivityTimeout: 2000,

  // custom
  vhs: {
    withCredentials: false
  },
  theme: {
    name: 'default',
    accentColor: 'blue'
  },
  offset: {
    start: null, // in seconds
    end: null // in seconds
  },
  hotKeys: {},
  stat: {},
  chromecast: {
    addButtonToControlBar: true
  },
  airPlay: {
    addButtonToControlBar: true
  },
  vr: false, // options here : https://github.com/videojs/videojs-vr#options
  trickplay: true,
  overlay: null,
  disableResolutionsFn: false, // should be false or a function with value parameter
  ready: null, // callback
  logLevel: 'warn'
}

export default class NFBWebPlayer {
  constructor ({ el, options }) {
    this.containerEl = this.getContainerEl(el)
    this.player = null
    this.hlsQualitySelector = null
    this.sprites = []

    this.options = { ...defaultOptions, ...options }
    this.turnOffDefaultCC = this.turnOffDefaultCC.bind(this)
    videojs.log.level(this.options.logLevel)

    const inheritVideoJSOptions = pick(this.options, [
      'poster',
      'controls',
      'autoplay',
      'loop',
      'muted',
      'preload',
      'language',
      'inactivityTimeout'
    ])

    this.videoJsOptions = {
      ...inheritVideoJSOptions,
      techOrder: ['html5'],
      controlBar: {
        muteToggle: false,
        volumeBar: false,
        volumePanel: {
          inline: true
        },
        pictureInPictureToggle: false
      },
      html5: {
        vhs: {
          handlePartialData: true,
          limitRenditionByPlayerDimensions: false, // limit quality to maximum player dimension
          enableLowInitialPlaylist: true,
          overrideNative: !videojs.browser.IS_SAFARI, // true causes bug in Safari (cf FRONT-637)
          withCredentials: this.options.vhs.withCredentials
        }
      },
      plugins: {}
    }

    this.checkRequiredOptions()
    this.init()

    return this
  }

  getContainerEl (el) {
    if (el === undefined) {
      throw new Error('Element container needs to be specified.')
    }

    if (typeof el === 'string') {
      const domEl = document.querySelector(el)
      if (domEl === null) {
        throw new Error(`Impossible to find element with selector "${el}"`)
      }

      return domEl
    } else if (el instanceof HTMLElement) {
      return el
    }
  }

  checkRequiredOptions () {
    if (!get(this.options, 'source')) {
      throw new Error('Source needs to be defined.')
    }
  }

  async init () {
    await this.loadLanguage(this.options.language)

    this.videoType = this.getTypeFromSource(this.options.source)
    videojs.log(`Video Type: ${this.videoType}`)
    switch (this.videoType) {
    case 'mp4':
      await import(/* webpackChunkName: "videojs-mp4-quality-selector" */'@silvermine/videojs-quality-selector')
        .then(m => {
          m.default(videojs)
        })
      break
    case 'hls':
    default:
      await import(/* webpackChunkName: "videojs-hls-quality-selector" */'videojs-hls-quality-selector')
      break
    }

    this.initTheme()

    if (this.options.dvSource) {
      await this.initAudioDescription()
    }

    if (this.options.chromecast) {
      await this.initChromecast()
    }

    if (this.options.airPlay) {
      await this.initAirPlay()
    }

    if (this.options.overlay) {
      await this.initOverlay()
    }

    if (this.options.hotKeys) {
      await this.initHotKeys()
    }

    if (this.options.trickplay && this.videoType === 'hls') {
      await this.initTrickPlay()
    }

    await this.initVideoJs()

    if (this.options.vr) {
      await this.initVR()
    }

    if (this.options.offset.start || this.options.offset.end) {
      await this.initOffset()
    }

    // Keep stat after offset as it needs the duration to be updated
    if (this.options.stat) {
      await this.initStat()
    }

    return this
  }

  async loadLanguage (locale) {
    videojs.log(`Loading language: ${locale}`)
    await import(/* webpackChunkName: "videojs-language" */ `@i18n/${locale}_CA.json`).then(json => {
      videojs.addLanguage(locale, json)
    })
  }

  getTypeFromSource (source) {
    return (typeof source === 'object') ? 'mp4' : 'hls'
  }

  async initAudioDescription () {
    videojs.log('Adding Audio Description')

    // Update plugins option
    this.videoJsOptions.plugins = {
      ...this.videoJsOptions.plugins,
      nfbAudioDescription: pick(this.options, ['source', 'dvSource'])
    }

    return import(/* webpackChunkName: "videojs-nfb-audio-description" */ '@plugins/NFBAudioDescription')
  }

  async initChromecast () {
    // add Chrome Sender API Library if not loaded https://developers.google.com/cast/docs/chrome_sender
    videojs.log('Adding Chrome Sender API Library')
    const script = document.createElement('script')
    script.setAttribute('src', '//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1')
    document.body.appendChild(script)

    return import(/* webpackChunkName: "videojs-chromecast" */ '@silvermine/videojs-chromecast').then(m => {
      m.default(videojs, { preloadWebComponents: true })
      this.videoJsOptions.techOrder = ['chromecast', 'html5']
      this.videoJsOptions.plugins = {
        ...this.videoJsOptions.plugins,
        chromecast: this.options.chromecast
      }
    })
  }

  async initAirPlay () {
    videojs.log('Adding Air Play')
    return import(/* webpackChunkName: "videojs-airplay" */ '@silvermine/videojs-airplay').then(m => {
      m.default(videojs)
      this.videoJsOptions.plugins = {
        ...this.videoJsOptions.plugins,
        airPlay: this.options.airPlay
      }
    })
  }

  async initOverlay () {
    videojs.log('Adding Overlay')

    // Cascade language
    this.options.overlay.language = this.options.language

    // Update plugins option
    this.videoJsOptions.plugins = {
      ...this.videoJsOptions.plugins,
      nfbOverlay: this.options.overlay
    }

    return import(/* webpackChunkName: "videojs-nfb-overlay" */'@plugins/NFBOverlay')
  }

  async initHotKeys () {
    videojs.log('Adding Hot Keys')

    // Update plugins option
    this.videoJsOptions.plugins = {
      ...this.videoJsOptions.plugins,
      nfbHotKeys: this.options.hotKeys
    }

    return import(/* webpackChunkName: "videojs-nfb-hotkeys" */'@plugins/NFBHotKeys')
  }

  async initTrickPlay () {
    videojs.log('Adding Trick Play')

    // Update plugins option
    this.videoJsOptions.plugins = {
      ...this.videoJsOptions.plugins,
      nfbTrickPlay: {
        source: this.options.source
      }
    }
    return await import(/* webpackChunkName: "videojs-nfb-trick-play" */'@plugins/NFBTrickPlay')
  }

  initTheme () {
    // Update plugins option
    this.videoJsOptions.plugins = {
      ...this.videoJsOptions.plugins,
      nfbTheme: this.options.theme
    }
  }

  async initVideoJs () {
    videojs.log('Create videojs player')
    this.player = videojs(this.containerEl, this.videoJsOptions)

    switch (this.videoType) {
    case 'hls':
      this.player.src({
        src: this.options.source,
        type: 'application/x-mpegURL',
        withCredentials: this.options.vhs.withCredentials
      })

      this.player.hlsQualitySelector({
        displayCurrentQuality: true
      })

      break

    case 'mp4':
      this.player.addClass('vjs-mp4-source')
      this.player.src(this.options.source)
      this.player.controlBar.addChild('QualitySelector')
      break

    default:
      throw new Error('Video type has to be HLS or MP4')
    }

    this.player.addClass(`vjs-language-${this.options.language}`)

    if (typeof (this.options.disableResolutionsFn) === 'function') {
      this.player.on('loadeddata', this.applyDisableResolutions.bind(this))
    }

    this.player.on('loadeddata', this.turnOffDefaultCC)

    if (this.options.ready && typeof this.options.ready === 'function') {
      this.player.on('ready', () => {
        this.options.ready(this.player)
      })
    }
  }

  applyDisableResolutions () {
    try {
      videojs.log('Disable resolutions from custom function (kind of hacky ¯\_(ツ)_/¯)') // eslint-disable-line
      const hlsQualitySelectorMenuItems = this.player.hlsQualitySelector._qualityButton.items
      forEach(hlsQualitySelectorMenuItems, menuItem => {
        if (this.options.disableResolutionsFn(menuItem.item)) {
          menuItem.hide()
        }
      })
    } catch (error) {
      videojs.log.warn(`Error when trying to disable resolution: ${error.message}`)
    }
  }

  async initVR () {
    videojs.log('Loading videojs-vr plugin')
    return await import(/* webpackChunkName: "videojs-vr" */ 'videojs-vr/dist/videojs-vr.min.js').then(() => {
      videojs.log('Video 360 is active')
      videojs.Hls.MAX_GOAL_BUFFER_LENGTH = 30
      videojs.Hls.GOAL_BUFFER_LENGTH = 15
      this.player.mediainfo = this.player.mediainfo || {}
      this.player.vr({ projection: this.options.vr.projection })
      videojs.log(`Video 360 projection type: ${this.options.vr.projection}`)
    })
  }

  async initOffset () {
    videojs.log('Loading videojs-offset plugin')
    return await import(/* webpackChunkName: "videojs-offset" */ 'videojs-offset').then(m => {
      videojs.log(`Offset starts at ${this.options.offset.start} and ends at ${this.options.offset.end}`)
      this.player.offset({
        start: this.options.offset.start,
        end: this.options.offset.end,
        restart_beginning: true
      })
    })
  }

  async initStat () {
    videojs.log('Adding Stat')
    return await import(/* webpackChunkName: "videojs-nfb-unstat" */'@plugins/NFBStat').then(() => {
      this.player.nfbStat(this.options.stat)
    })
  }

  turnOffDefaultCC () {
    // default CC to off @todo : fix native in Safari
    if (this.player.remoteTextTracks().length) {
      videojs.log('Turn off default CC')
      // get "off" element (second one) and trigger a click
      const children = this.player.controlBar.subsCapsButton.menu.children()
      if (children[1]) {
        children[1].handleClick()
      }
    }
  }

  destroy () {
    this.player.dispose()
    this.player = null
  }
}
