import videojs from 'video.js'
import '@less/theme/default.less'

const Plugin = videojs.getPlugin('plugin')

// Default options for the plugin.
const defaults = {
  name: 'default',
  accentColor: null
}

/**
 * An advanced Video.js plugin. For more information on the API
 *
 * See: https://blog.videojs.com/feature-spotlight-advanced-plugins/
 */
class NFBTheme extends Plugin {
  /**
   * Create a NFBTheme plugin instance.
   *
   * @param  {Player} player
   *         A Video.js Player instance.
   *
   * @param  {Object} [options]
   *         An optional options object.
   *
   *         While not a core part of the Video.js plugin architecture, a
   *         second argument of options is a convenient way to accept inputs
   *         from your plugin's caller.
   */
  constructor (player, options) {
    // the parent class will add player under this.player
    super(player)
    this.log.level(videojs.log.level())

    this.options = videojs.mergeOptions(defaults, options)
    this.domEls = {}
    this.playerEl = this.player.el()
    this.nativeControls = false
    this.hasFirstPlay = false
    this.init = this.init.bind(this)
    this.onLoaded = this.onLoaded.bind(this)
    this.onFirstPlay = this.onFirstPlay.bind(this)

    this.player.on('ready', this.init)
    this.player.on('loadeddata', this.onLoaded)

    // For native player, we want to hide controls until first play
    this.player.one('firstplay', this.onFirstPlay)
  }

  init () {
    this.log('Initializing NFB Theme plugin')
    this.player.addClass(`vjs-nfb-theme-${this.options.name}`)
    this.initColors()
    this.updateDOM()
  }

  onLoaded () {
    this.updateButtonOrder()
    this.addTitleLegendToQualitySelector()

    if (videojs.browser.IS_IPHONE) {
      this.enableNativeControls()
    }
  }

  initColors () {
    if (this.options.accentColor) {
      this.log('Update Accent Color')
      if (/(^#[a-zA-Z0-9]{3}$|^#[a-zA-Z0-9]{6}$)/.test(this.options.accentColor)) {
        document.documentElement.style.setProperty('--accent-color', this.options.accentColor)
      } else {
        this.player.addClass(`accent-${this.options.accentColor}`)
      }
    }
  }

  updateDOM () {
    this.log('Update DOM')
    this.domEls = {
      controlBar: this.playerEl.querySelector('.vjs-control-bar'),
      progressControlContainer: document.createElement('div'),
      progressControl: this.playerEl.querySelector('.vjs-progress-control')
    }

    // move progress bar inside a different container
    this.domEls.progressControlContainer.classList.add('vjs-progress-control-container')
    this.domEls.controlBar.prepend(this.domEls.progressControlContainer)
    this.domEls.progressControlContainer.appendChild(this.domEls.progressControl)
  }

  updateButtonOrder () {
    this.log('Update Button Order')
    const subsCaps = this.playerEl.querySelector('.vjs-subs-caps-button.vjs-control')
    const audioDescription = this.playerEl.querySelector('.vjs-audio-description-button.vjs-control')
    const qualitySelector = this.playerEl.querySelector('.vjs-quality-selector.vjs-control')
    const fullscreen = this.playerEl.querySelector('.vjs-fullscreen-control.vjs-control')
    const chromecast = this.playerEl.querySelector('.vjs-chromecast-button.vjs-control')

    const container = document.createElement('div')
    container.className = 'vjs-control-custom-container'
    if (subsCaps) {
      container.appendChild(subsCaps)
    }
    if (audioDescription) {
      container.appendChild(audioDescription)
    }
    if (qualitySelector) {
      container.appendChild(qualitySelector)
    }
    if (fullscreen) {
      container.appendChild(fullscreen)
    }
    if (chromecast) {
      container.appendChild(chromecast)
    }
    this.domEls.controlBar.appendChild(container)
  }

  addTitleLegendToQualitySelector () {
    this.log('Add title legend to quality selector button')
    if (this.player.hasPlugin('hlsQualitySelector')) {
      const qualitySelectorButton = this.playerEl.querySelector('.vjs-quality-selector.vjs-control button')
      if (qualitySelectorButton) {
        qualitySelectorButton.setAttribute('title', this.player.localize('Quality Selector'))
      }
    }
  }

  onFirstPlay () {
    // For native player, we want to hide controls until first play
    this.hasFirstPlay = true
    if (this.nativeControls) {
      this.playerEl.querySelector('video').setAttribute('controls', true)
    }
  }

  enableNativeControls () {
    this.log('Enable native controls')
    this.nativeControls = true
    this.player.usingNativeControls(true)
    const videoEl = this.playerEl.querySelector('video')
    videoEl.setAttribute('controls', true)
    videoEl.setAttribute('poster', this.player.poster())
    if (!this.hasFirstPlay) {
      // For native player, we want to hide controls until first play
      videoEl.removeAttribute('controls')
    }
  }
}

// Define default values for the plugin's `state` object here.
NFBTheme.defaultState = {}

// Include the version number.
NFBTheme.VERSION = '1.0.0'

// Register the plugin with video.js.
videojs.registerPlugin('nfbTheme', NFBTheme)

export default NFBTheme
